<template>
  <div id="activitydetails" class="j_a_center" :class="isMobile ? 'ismobile' : 'ispc'">
    <!-- <div class="iframe_wrapper" v-show="iframetoggle">
      <span class="iconfont icon-shanchu" @click="iframetoggle = false"></span>
      <iframe
        src="https://chat.800ts.com.cn/ChatWeb/WebPages/CM/ChatCustomer.html"
        style="width: 602px; height: 542px; border: none"
      ></iframe>
    </div> -->
    <img
      v-if="isMobile"
      src="~@/assets/images/activitydetailsbg_mobile.png"
      alt=""
    />
    <img v-else src="~@/assets/images/activitydetailsbg.png" alt="" />
    <span :class="isMobile ? 'applymobile' : 'apply'" @click="apply"
      >申请体验</span
    >
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      scrollable
      @show="resetModal"
      @hidden="resetModal"
      modal-class="modal-form"
      size="sm"
      centered
      hide-footer
    >
      <template #modal-title>
        <div class="submit-head">
          <img
            class="form-head"
            src="~@/assets/images/formHead@2x.png"
            alt=""
            srcset=""
          />
        </div>
      </template>
      <template #modal-header-close>
        <span class="font_family icon-guanbi"></span>
      </template>
      <apply-form></apply-form>
    </b-modal>
    <div class="fixed-btns" :style="{ right: btnRight }" v-if="!isMobile">
      <div class="wx-message">
        <div class="btn btn-message" @click.stop="openChatmaster">
          <!-- <span class="icomoon icon-wx"></span> -->
          <span class="iconfont icon-zaixianzixun1"></span>
          <div>在线联系</div>
        </div>
        <!-- <div class="wx-qrcode" v-show="showWxCode">
          <img src="~@/assets/images/contact-wx@2x.png" alt="" />
          <div>手机扫码添加售前专家</div>
        </div> -->
      </div>
      <!-- <div
        class="btn btn-top"
        :class="{ show: showBackTop }"
        @click="backToTop"
      >
        <span class="icomoon icon-backtop"></span>
      </div> -->
    </div>
  </div>
</template>

<script>
import applyForm from "@/components/applyForm";
import utils from "@/utils";
let timer = null;

export default {
  components: {
    applyForm,
  },
  data() {
    return {
      status: 0,
      isMobile: utils.isMobile(),
      btnRight: utils.isMobile() ? "24px" : "40px",
      showBackTop: false, // 显示返回顶部按钮
      iframetoggle:false

    };
  },
  watch: {
    pageYOffset(v) {
      if (v > window.innerHeight) {
        this.showBackTop = true;
      } else {
        this.showBackTop = false;
      }
    },
  },
  methods: {
    resetModal() {},
    apply() {
      if (!utils.isMobile()) {
        this.$bvModal.show("modal-prevent-closing");
      } else {
        this.$router.push({
          name: "SubmitPage",
        });
      }
    },
    openChatmaster() {
      // this.iframetoggle = true;
      window.open(
        "https://chat.800ts.com.cn/ChatWeb/WebPages/CM/ChatCustomer.html",
        "_blank"
      );
    },
    backToTop() {
      let vm = this;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        let oTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        let ispeed = Math.floor(-oTop / 8);
        if (oTop > 0) {
          if (oTop > window.innerHeight) {
            vm.showBackTop = true;
          } else {
            vm.showBackTop = false;
          }
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop + ispeed;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./home.scss";
@import "../assets/iconfont/iconfont.css";
#activitydetails {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
  }
  .apply {
    background: red;
    padding: 0 30px;
    height: 60px;
    width: 300px;
    display: flex;
    align-items: center;
    color: #fff;
    margin-top: 30px;
    border-radius: 20px;
    cursor: pointer;
    position: absolute;
    bottom: 7%;
    z-index: 100;
    right: 24%;
    opacity: 0;
  }
  .applymobile {
    background: red;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    margin-top: 30px;
    border-radius: 20px;
    cursor: pointer;
    position: absolute;
    bottom: 8.5em;
    z-index: 100;
    left: 0;
    opacity: 0;
  }
}

.iframe_wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  span {
    background: #000;
    cursor: pointer;
    color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -15px;
    top: -15px;
    border-radius: 50%;
  }
}
.ispc .btn-message .iconfont {
  font-size: 30px;
  padding: 6px 0;
}
.ismobile .btn-message .iconfont {
  font-size: 24px;
  padding: 8px 0 0px;
}
.btn-top.show{
  visibility:inherit;
}
</style>